<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>
          我今年42岁，和丈夫一起在国内经商，公司情况还算不错。我有一个非常可爱的女儿，为了她能获得更自由全面的教育和生活环境，初中就送她去了美国念书，由我陪读，丈夫留在国内操持生意。</p>
        <p>
          我在陪读过程中发现，虽然作为留学生可以享受到美国先进的教育资源，但还是遇到了种种不如意。我的女儿在美国读书成绩很好，和同学们也相处融洽，但时不时会跟我抱怨说有哪些活动同学们都去了，但她因为是外国人没有资格参加。虽然女儿很懂事，但作为母亲看着特别心疼和委屈。原来，作为留学生虽然可以和本土学生一起上课，但有好些当地的夏令营和社会实践活动无法参加，而这些课外活动正是中美教育差异的重要一环；我在和学校教职人员沟通后进一步了解到，作为留学生，将来在报考高中和大学的时候也会有很多限制，比如有限的专业选择和较少的留学生录取名额等。为了让优秀的女儿能和周围的同学朋友获得平等的发展机会，我和丈夫商量下来决定办理美国移民。</p>
        <p>我在网络上搜索“<a href="/usa/">美国移民</a>”，看到了跃迁公司的广告和新闻，了解到跃迁公司在<a
            href="/eb5profile">美国EB-5投资移民项目</a>上，<a href="/">跃迁公司</a>迄今保持着三个100%的成功率：100%条件绿卡成功率、100%永久绿卡成功率、100%投资还款成功率。我们要办就肯定要一次性成功，对美国EB-5移民的概念和流程有了初步了解。由于我丈夫一人在国内打理公司非常忙碌，跃迁公司热情地派了咨询顾问上门和我丈夫面谈。经顾问的专业建议，我们决定用公司借款的方式来解释50万美金的合法来源。跃迁公司指定了专门的文档经理负责我的案子，从投资到文件准备都提供了专业及时的指导，后续文件也很快准备齐全递交了移民局。
        </p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/11/1.jpg">
        </p>
        <p>
          很快，我们在跃迁和移民律师的帮助下递交了移民申请，我女儿也顺利地考入了当地的高中，绿卡身份在手，不但学费省了，将来考大学也不用担心了。
        </p>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases13",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>